import React from "react";
import WorkImg from "../assets/work.png";

const Hero = () => {
  return (
    <div name="home" className="w-full h-screen bg-[#0a192f]">
      {/* Container */}
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full text-center">
        <p className="text-3xl text-pink-600 pb-8">
          Das neue Layout der Seite wird gerade erstellt
        </p>
        <img src={WorkImg} alt="Construction" />
        <h1 className="text-gray-400 text-2xl pt-8">Sei gespannt</h1>
      </div>
    </div>
  );
};

export default Hero;
